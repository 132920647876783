import { Validators } from "@angular/forms";
import gql from "graphql-tag";
import { FieldConfig, FieldType } from "src/app/shared/components/dynamic-forms-components/field.interface";
import { basePageableGraphqlField } from "src/app/store/base.graphql";

export const ChildinstitutionFields = `
active
acrony
defaultInstitution
deleted
email
fax
handlingMethodType
id
institutionCode
ictSupportEmail
name
pathLogo
physicalAddress
postalAddress
telephone
tin
uuid
voteCode
website
institutionType
incidentInitialCode
gispCode
ldapConfig{
id
uuid
ldapBaseDn
}
helpdeskServiceProvider
`;

export const institutionFields = `
  active
  acronym
  authMode
  ldapConfig{
  id
  uuid
  ldapUrl
  }
  defaultInstitution
  deleted
  email
  fax
  incidentHandlingMethodType
  incidentClosingDuration
  id
  institutionCode
  name
  pathLogo
  physicalAddress
  postalAddress
  telephone
  tin
  ictSupportEmail
  uuid
  voteCode
  institutionType
  website
  canReportToBranches
  helpdeskServiceProvider
  gispCode
  administrativeArea{
    id
    uuid
    areaName
  }
  institutionCategory{
    id
    uuid
    name
  }
  creatorInstitution{
    id
    name
    uuid
  }
  incidentInitialCode
  internalIncidentInitialCode
  storageSizeGivenInGb
  helpdeskServiceProvider
`;

export const institutionFewFields = `
  defaultInstitution
  id
  name
  uuid
  canReportToBranches
  helpdeskServiceProvider
  gispCode
  incidentInitialCode
  internalIncidentInitialCode
`;

export const INSTITUTION_LITS = gql`
  query listOfInstitutions($pageParam: PageableParamInput){
    listOfInstitutions(pageParam: $pageParam){
     content{
        ${institutionFields}
      }
      totalElements
    totalPages
    size
    last
    first
    }
  }
`;

export const INSTITUTION_LIST_FOR_OPTION = gql`
  query listOfInstitutions($pageParam: PageableParamInput) {
    listOfInstitutions(pageParam: $pageParam) {
      content {
        id
        uuid
        institutionCode
        name
        acronym
        
      }
    }
  }
`;

export const AVAILABLE_SERVICE_PROVIDER_INSTITUTION = gql`
  query getAllServiceProviderInstitutionsIrrespectly($pageParam: PageableParamInput){
    getAllServiceProviderInstitutionsIrrespectly(pageParam: $pageParam){
      content{
        ${institutionFields}
      }
      totalElements
    totalPages
    size
    last
    first
    }
  }
`;

export const GET_MY_SERVICE_SUBSCRIBER_BY_CONFIMED_STATUS = gql`
  query getMyServiceSubscribersByConfirmedStatus($confirmed: Boolean, $pageParam: PageableParamInput) {
    getMyServiceSubscribersByConfirmedStatus(confirmed: $confirmed, pageParam: $pageParam) {
      content {
        id
        uuid
        clientInstitution {
          name
        }
        confirmed
        service {
          id
          uuid
          name
        }
      }
      totalElements
      totalPages
      size
      last
      first
    }
  }
`;

export const CONFIRM_SUBSCRIPTION = gql`
  mutation confirmSubscription($clientId: Long) {
    confirmSubscription(clientId: $clientId) {
      code
      data {
        id
        uuid
        clientInstitution {
          name
        }
        confirmed
        service {
          uuid
          name
        }
      }
    }
  }
`;

export const DELETE_SUBSCRIPTION_CLIENT_CLIENT_FROM_MYINSTITUTION = gql`
  mutation deleteClientFromMyInstitution($clientId: Long) {
    deleteClientFromMyInstitution(clientId: $clientId) {
      code
      data
      message
      status
    }
  }
`;

export const GET_ACTIVE_INSTITUTION_LIST = gql`
query getAllActiveInstitutions($deleted:Boolean,$active:Boolean){
  getAllActiveInstitutions(deleted:$deleted,active:$active){
        ${institutionFields}
  }
}
`;

export const CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION = gql`
query clientsSubscribedForServicesInMyInst($search: String, $pageParam: PageableParamInput){
  clientsSubscribedForServicesInMyInst(search: $search, pageParam: $pageParam){
    content{
      ${institutionFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION_BY_SERVICE_ID = gql`
query getAllClientsSubscribedForService($serviceId: Long,$pageParam: PageableParamInput){
  getAllClientsSubscribedForService(serviceId:$serviceId,pageParam:$pageParam){
    content{
      active
      id
      uuid
      clientInstitution{
        ${institutionFields}
      }
      
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const MY_INSTITUTION_SERVICE_PROVIDERS = gql`
query getAllServiceProviders($pageParam: PageableParamInput){
  getAllServiceProviders(pageParam:$pageParam){
    content{
      ${institutionFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const CREATE_OR_UPDATE_INTITUTIONS = gql`
mutation createUpdateInstitutions($institution:InstitutionInputDtoInput){
  createUpdateInstitutions(institution:$institution){
    code
    data{
      ${institutionFields}
    }
    message
    status
  }
}
`;

export const GET_BY_INSTITUTION_UUID = gql`
  query getInstitutionByUuid($uuid: String){
    getInstitutionByUuid(uuid: $uuid){
      code
      data {
        ${institutionFields}
      }
    }
  }
`;

export const DEACTIVATE_INSTITUTION_DETAILS = gql`
mutation deactivateInstitution($id: Long){
  deactivateInstitution(id:$id){
    code
    data{
      ${institutionFields}
    }
    message
    status
  }
}
`;

export const DELETE_INSTITUTION_DETAILS = gql`
mutation deleteInstitution($id:Long){
  deleteInstitution(id:$id){
    code
    data{
      ${institutionFields}
    }
    message
    status
  }
}
`;

export const SYNC_INSTITUTION_TO_GISP = gql`
  mutation syncInstitutionFromGisp {
    syncInstitutionFromGisp {
      code
      message
      status
    }
  }
`;

export const GET_MY_INSTITUTION_BY_ID = gql`
query getInstitutionById($id:Long){
  getInstitutionById(id:$id){
    code
    data{
      ${institutionFields}
    }
    message
    status
  }
}
`;

export const TOTAL_INSTITUTION_COUNTS = gql`
  query listOfInstitutions($pageParam: PageableParamInput) {
    listOfInstitutions(pageParam: $pageParam) {
      content {
        active
        deleted
      }
      numberOfElements
      totalElements
    }
  }
`;

export const TOTAL_INSTITUTION_STATISTICS = gql`
  query getInstitutionStatistics {
    getInstitutionStatistics {
      code
      data {
        activeInstitutions
        deletedInstitutions
        inActiveInstitutions
        privateInstitutions
        publicInstitutions
        totalInstitutions
      }
      message
      status
    }
  }
`;

export const GET_MY_INSTITUTION_BY_UUID = gql`
  query getInstitutionByUuid($uuid: String) {
    getInstitutionByUuid(uuid: $uuid) {
      code
      message
      data {
        id
        name
        uuid
      }
    }
  }
`;

export const SEND_INCIDENT_HANDLING_MODE = gql`
  mutation setIncidentHandlingMode($handlingMethod: IncidentHandlingMethodType) {
    setIncidentHandlingMode(handlingMethod: $handlingMethod) {
      code
      data {
        id
        uuid
        name
        incidentHandlingMethodType
        canReportToBranches
      }
    }
  }
`;

export const BASE64_INSTITUTION_LOGO = gql`
  query logoBase64ContentByInstitutionUuid($institutionUuid: String) {
    logoBase64ContentByInstitutionUuid(institutionUuid: $institutionUuid) {
      code
      data
      message
    }
  }
`;

export const BASE64_ATTACHMENT_BY_URL = gql`
  query uaaBase64ContentAttachmentsByfileUrl($fileUrl: String) {
    uaaBase64ContentAttachmentsByfileUrl(fileUrl: $fileUrl) {
      code
      data
      message
    }
  }
`;

export const GET_CLIENTS_INSTITUTIONx = gql`
query getAllClients($pageParam: PageableParamInput){
  getAllClients(pageParam:$pageParam){
    content{
      active
      id
      uuid
      clientInstitution{
        ${institutionFields}
      }
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const GET_CLIENTS_INSTITUTION = gql`
query getAllClients($pageParam: PageableParamInput){
  getAllClients(pageParam:$pageParam){
    content{
      ${institutionFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const GET_INSTITUTION_LIST_FOR_PAGINATED_SELECT = gql`
  query listOfInstitutions($pageParam: PageableParamInput) {
    listOfInstitutions(pageParam: $pageParam) {
      content {
        id
        name
        uuid
        institutionCode
      }
      totalElements
      totalPages
      size
      last
      first
    }
  }
`;

// STORAGE
export const storageFields = `
      folderSize
      folderSizeInByte
      institutionName
      institutionUuid
      storageSizeGivenInGb
`;
export const MY_INSTITUTION_STORAGE_USAGE = gql`
  query myInstitutionStorageUsage{
    myInstitutionStorageUsage{
      code
      data{
         ${storageFields}
      }
    }
  }
`;

export const GET_INSTITUTION_STORAGE_USAGE = gql`
  query institutionStorageUsage($institutionUuid: String){
    institutionStorageUsage(institutionUuid: $institutionUuid){
      code
      data{
        ${storageFields}
      }
    }
  }
`;

export const ALL_INSTITUTION_STORAGE_USAGE = gql`
    query getInstitutionStorageUsages{
        getInstitutionStorageUsages{
            ${storageFields}
        }
    }
`;

export const institutionDepartmentsFields = `
  uuid
      departmentName
      subDepartments {
        departmentName
        uuid
      }
`;

export const GET_INSTITUTION_DEPARTMENT_SELECT = gql`
  query getAllDepartments($parentDepartment: Boolean) {
    getAllDepartments(parentDepartment: $parentDepartment) {
      ${institutionDepartmentsFields}
    }
  }
`;

export const CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION_FEW_FIELDS = gql`
query clientsSubscribedForServicesInMyInst($search: String, $pageParam: PageableParamInput){
  clientsSubscribedForServicesInMyInst(search: $search, pageParam: $pageParam){
    content{
      ${institutionFewFields}
   
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const MY_INSTITUTION_SERVICE_PROVIDERS_FEW_FIELDS = gql`
query getAllServiceProviders($pageParam: PageableParamInput){
  getAllServiceProviders(pageParam:$pageParam){
    content{
      ${institutionFields}
    }
    ${basePageableGraphqlField}
  }
}
`;

export const GET_MY_NON_EMPLOYEE_SERVICE_PROVIDERS = gql`
    query  getMyServiceProviders($pageParam: PageableParamInput){
          getMyServiceProviders(pageParam:$pageParam){
            content{
              ${institutionFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_MY_NON_EMPLOYEE_SERVICE_PROVIDERS_FOR_LIST = gql`
    query  getMyServiceProviders($pageParam: PageableParamInput){
          getMyServiceProviders(pageParam:$pageParam){
            content{
              id
              uuid
              name
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const instSearchFields: FieldConfig[] = [
  {
    type: FieldType.paginatedselect,
    label: "Select Customer",
    key: "client",
    query: GET_INSTITUTION_LIST_FOR_PAGINATED_SELECT,
    queryKey: "listOfInstitutions",
    useObservable: true,
    optionName: "name",
    optionValue: "uuid",
    validations: [{ message: "Customer is Required", validator: Validators.required, name: "required" }],
    rowClass: "col12",
    searchFields: ["name", "voteCode", "gispCode", "email", "internalIncidentInitialCode"],
  },
];

export const GET_INSTITUTION_MANAGEMENT_STATISTICS = gql`
  query getInstitutionManagementStatistics {
    getInstitutionManagementStatistics {
      code
      data {
        branches
        departments
        designations
        roles
        serviceType
        services
        users
      }
    }
  }
`;

export const UPLOAD_CLIENTS = gql`
mutation importInstitutionClientLocalCodes($institutionUuid: String, $clientLocalCodeDtos: [InstitutionClientCodeByShIdDtoInput]){
  importInstitutionClientLocalCodes(institutionUuid: $institutionUuid, clientLocalCodeDtos: $clientLocalCodeDtos){
    id
  }
}

`;
