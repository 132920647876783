import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Department } from './department.model';
import { Institution } from '../institution/institution.model';

export const loadDepartments = createAction(
  '[Department/API] Load Departments',
  props<{ departments: Department[] }>()
);

export const addDepartment = createAction(
  '[Department/API] Add Department',
  props<{ department: Department }>()
);

export const upsertDepartment = createAction(
  '[Department/API] Upsert Department',
  props<{ department: Department }>()
);

export const addDepartments = createAction(
  '[Department/API] Add Departments',
  props<{ departments: Department[] }>()
);

export const upsertDepartments = createAction(
  '[Department/API] Upsert Departments',
  props<{ departments: Department[] }>()
);

export const updateDepartment = createAction(
  '[Department/API] Update Department',
  props<{ department: Update<Department> }>()
);

export const updateDepartments = createAction(
  '[Department/API] Update Departments',
  props<{ departments: Update<Department>[] }>()
);

export const deleteDepartment = createAction(
  '[Department/API] Delete Department',
  props<{ id: number }>()
);

export const deleteDepartments = createAction(
  '[Department/API] Delete Departments',
  props<{ ids: number[] }>()
);

export const clearDepartments = createAction(
  '[Department/API] Clear Departments'
);

// Effect Actions
export const listAllDepartmentsByInstitution = createAction(
  '[Department/API] List All Departments By Institution',
  props<{institution: {id: number}}>()
);
export const listAllDepartments = createAction(
  '[Department/API] List of All Departments',
);
export const listAllDepartmentsForOptions = createAction(
  '[Department/API] List of All Departments For Options',
);

export const getDepartmentById = createAction(
  '[Department/API] Get Department By Id',
  props<{ id: number }>()
);

export const getDepartmentByCode = createAction(
  '[Department/API] Get Department By Code',
  props<{ departmentCode: string }>()
);

export const modifyDepartment = createAction(
  '[Department/API] Modify Department Details',
  props<{  id: number; department: Department }>()
);

export const createDepartment = createAction(
  '[Department/API] Create Department',
  props<{ department: Department }>()
);

export const removeDepartment = createAction(
  '[Department/API] Remove Department By Id',
  props<{ id: number }>()
);

export const getDepartmentByUuid = createAction(
  '[Department/API] get Department By uid',
  props<{ departmentUid: string }>()
);

export const getDefaultDepartment = createAction(
  '[User/API] Get Default Department'
);
