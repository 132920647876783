import {Institution} from 'src/app/store/entities/settings/institution/institution.model';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {NotificationService} from 'src/app/services/notification.service';
import {Observable, Subscription} from 'rxjs';
import * as UserActions from 'src/app/store/entities/settings/user/user.actions';
import {AppState} from 'src/app/store/reducers';
import {ActivatedRoute} from '@angular/router';
import {selectUsersByInstitutionUuid} from 'src/app/store/entities/settings/user/user.selectors';
import {User, UserSearchFields} from 'src/app/store/entities/settings/user/user.model';
import {AddInstitutionUserComponent} from './add-institution-user/add-institution-user.component';
import {selectInstitutionByUuid} from 'src/app/store/entities/settings/institution/institution.selectors';
import {initializedPageableParameter, PageableParam} from '../../../../interfaces/global.interface';
import {AssignRoleComponent} from '../../../../modules/settings/users/assign-role/assign-role.component';
import {StorageService} from '../../../../services/storage.service';

@Component({
    selector: 'app-institution-user',
    templateUrl: './institution-user.component.html',
    styleUrls: ['./institution-user.component.scss']
})
export class InstitutionUserComponent implements OnInit {

    title: string;
    instUsers$: Observable<User[]>;
    institution$: Observable<Institution>;
    institution: Institution;
    tableColumns: any;
    searchFields = UserSearchFields;
    institutionUuid: string;

    pageable: PageableParam = initializedPageableParameter;
    subscription: Subscription = new Subscription();

    @Input() institutionInput: Institution;

    iamHelpdeskServiceProvider: boolean;
    myInstitutionUuid: string;

    userType: any;

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private storageService: StorageService
    ) {
        this.iamHelpdeskServiceProvider = this.storageService.getItem('helpdeskServiceProvider');
        this.myInstitutionUuid = this.storageService.getItem('institutionUuid');
        this.userType = storageService.getItem('userType');

    }

    ngOnInit(): void {
        this.loadData();
        this.title = 'Institution User List';
        this.tableColumns = {
            no: 'SN',
            fullName: 'Full Name',
            email: 'E-Mail',
            phone: 'Phone',
            isActive: 'Status',
            action: 'Actions'
        };

        this.instUsers$ = this.store.pipe(select(selectUsersByInstitutionUuid({uuid: this.institutionUuid})));
        this.institution$ = this.store.pipe(select(selectInstitutionByUuid({uuid: this.institutionUuid})));
        this.subscription.add(
            this.institution$.subscribe(data => this.institution = data)
        );

    }

    spCondition(data: User) {
        if (this.iamHelpdeskServiceProvider && this.institutionInput?.helpdeskServiceProvider) {
            // console.log('BOTH SP');
            return this.institutionCondition(data);
        } else if (this.iamHelpdeskServiceProvider && !this.institutionInput?.helpdeskServiceProvider) {
            // console.log('IM ONLY SP');
            return true;
        } else {
            // console.log('BOTH NOT  SP');

            return false;
        }
    }

    institutionCondition(data: User) {
        return data?.registeredByInstitution?.uuid === this.myInstitutionUuid;
    }


    createNewUsers(data?: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.data = {institution: this.institutionInput, user: data};
        this.dialog.open(AddInstitutionUserComponent, dialogConfig);
    }

    editUser(data: User) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.data = {institution: this.institutionInput, user: data};
        this.dialog.open(AddInstitutionUserComponent, dialogConfig);
    }

    assignRole(user: User) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        dialogConfig.data = {user, type: 'external'};
        this.dialog.open(AssignRoleComponent, dialogConfig);
    }


    deleteuser(user: User) {
        const message = 'Are you sure?';
        this.notificationService.confirmation(message, 'Delete').then(
            data => {
                if (data.value) {
                    this.store.dispatch(UserActions.deleteUserDetails({id: user.id}));
                }
            }
        );
    }

    activateUser(user: User) {
        this.store.dispatch(UserActions.activateUser({id: user?.id}));
    }

    deActivateUser(user: User) {
        this.store.dispatch(UserActions.deactivateUser({id: user?.id}));
    }


    loadMore(pageable: PageableParam) {
        this.pageable = pageable;
        this.loadData();
    }

    loadData() {
        this.institutionUuid = this.institutionInput?.uuid;
        this.store.dispatch(UserActions.clearUsers());
        this.store.dispatch(UserActions.listAllUsersByInstitutionID({
                institutionId: this.institutionUuid,
                pageable: this.pageable
            }
        ));
    }

}
