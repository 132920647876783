import { ResponseCodeService } from "../../../../services/response-code.service";
import { NotificationService } from "../../../../services/notification.service";
import { AppState } from "../../../reducers";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Apollo } from "apollo-angular";
import * as fromActions from "./user.actions";
import * as fromGraphql from "./user.graphql";
import { map, switchMap } from "rxjs/operators";
import { StorageService } from "../../../../services/storage.service";
import { PaginationService } from "src/app/services/pagination.service";
import { initializedPageableParameter } from "src/app/interfaces/global.interface";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UserFormComponent } from "src/app/modules/settings/users/user-form/user-form.component";
import { User } from "./user.model";
import { SettingsService } from "../../../../services/settings.service";
import { AuthService } from "../../../../services/auth.service";

@Injectable()
export class UserEffects {
  //  list user by Institution
  listAllUsersByInstitution$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.listAllUsersByInstitutionID),
        switchMap((action) => {
          return this.apollo
            .query({
              query: action?.option == "FEW" ? fromGraphql.GET_USER_BY_INSTITUTION_FEW_FIELDS : fromGraphql.GET_USER_BY_INSTITUTION,
              variables: {
                institutionId: action.institutionId ? action.institutionId : null,
                pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Institution Users"),
              map(({ data }: any) => {
                if (data) {
                  const result = data?.getAllUsersByInstitutionId;
                  this.paginationService.setPage(result);
                  this.store.dispatch(fromActions.loadUsers({ users: result?.content }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // import LDAP users
  importLdapUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.importLdapUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.IMPORT_LDAP_USERS,
              variables: {
                institutionUuid: action.institutionUuid ? action.institutionUuid : null,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching LDAP Users"),
              map(({ data }: any) => {
                if (data) {
                  // console.log("importLdapUsers response : ", data);

                  if (data) {
                    this.notificationService.successMessage("Operation successful: ");
                  } else {
                    this.notificationService.errorMessage("Problem occurred while fetching LDAP Users");
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // List All Users
  listAllUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.listAllUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.LIST_ALL_USERS,
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Users"),
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.listOfUserAccounts }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );
  listAllUsersForList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.listAllUsersForList),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.LIST_ALL_USERS_FOR_LIST,
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Users"),
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.listOfUserAccounts }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // List All Users
  getApprovalStageUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getApprovalStageUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_APPROVAL_STAGE_USER,
              variables: {
                stageUuid: action.stageUuid,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Approval Stage Users"),
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.getApprovalStageUsers.dataList }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // assignRoleToUser
  assignRoleToUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.assignRoleToUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ASSIGN_ROLE_USER,
              variables: {
                roleIds: action.roleIds,
                userUuid: action.userUuid,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving user"),
              map(({ data }: any) => {
                if (data) {
                  const response = this.responseCodesService?.getCodeDescription(data.assignRoleToUser.data);
                  this.notificationService.successMessage("Operation successful: " + data.assignRoleToUser.code);
                } else {
                  this.notificationService.errorMessage("Role assignment failed: " + data.assignRoleToUser.code);
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // assignRoleToExternalUser
  assignRoleToExternalUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.assignRoleToExternalUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ASSIGN_ROLE_TO_EXTERNAL_USER,
              variables: {
                roleIds: action.roleIds,
                userUuid: action.userUuid,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving user"),
              map(({ data }: any) => {
                if (data) {
                  const response = data.assignRoleToExternalUser;
                  if (response.code === 9000) {
                    this.notificationService.successMessage("Operation successful");
                    this.store.dispatch(fromActions.upsertUser({ user: response.data }));
                  } else {
                    this.notificationService.handleErrorMessage(response);
                  }
                } else {
                  this.notificationService.errorMessage("Failed to assign role");
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  createinstitutionUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.createNewUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_USER,
              variables: {
                user: action.userDto,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving User"),
              map(({ data }: any) => {
                if (data) {
                  const response = this.responseCodesService?.getCodeDescription(data.createUpdateUserAccount.code);
                  if (data.createUpdateUserAccount.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.createUpdateUserAccount.data }));
                    if (action.userDto?.uuid) {
                      this.notificationService.successMessage("User Edited Successfully");
                    } else {
                      this.notificationService.successMessage("User Registered Successfully");
                    }
                  } else {
                    this.notificationService.errorMessage(data?.createUpdateUserAccount?.message + data.createUpdateUserAccount.code);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  registerUsersInBatch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.registerUsersInBatch),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_USERS_IN_BATCH,
              variables: {
                usersFromOtherSystem: action.usersFromOtherSystem,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving User"),
              map(({ data }: any) => {
                if (data) {
                  const response = this.responseCodesService?.getCodeDescription(data.registerUsersInBatch.code);
                  if (data.registerUsersInBatch.code === 9000) {
                    // this.store.dispatch(fromActions.upsertUser({ user: data.registerUsersInBatch.data }));
                    this.store.dispatch(fromActions.listAllUsers());
                    let unRegisteredUsers = data.registerUsersInBatch.dataList.filter((user) => user?.registered === false);
                    if (unRegisteredUsers?.length > 0) {
                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus = true;
                      dialogConfig.width = "45%";
                      // dialogConfig.panelClass = 'custom-modalbox';
                      dialogConfig.data = { payload: unRegisteredUsers, type: "importUserReview" };
                      this.dialog.open(UserFormComponent, dialogConfig);
                    }
                    this.notificationService.successMessage("Users were registered successfully");
                  } else {
                    this.notificationService.errorMessage("Failed to register users: " + data.registerUsersInBatch.code);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  createUpdateMyInstitutionUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.createUpdateMyInstitutionUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.CREATE_MY_INSTITUTION_USER,
              variables: {
                user: action.userDto,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving User"),
              map(({ data }: any) => {
                if (data) {
                  const response = this.responseCodesService?.getCodeDescription(data.createUpdateMyInstUserAccount.code);
                  if (data.createUpdateMyInstUserAccount.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.createUpdateMyInstUserAccount.data }));
                    if (action.userDto?.uuid) {
                      this.notificationService.successMessage("User Edited Successfully");
                    } else {
                      this.notificationService.successMessage("User Registered Successfully");
                    }
                  } else {
                    this.notificationService.errorMessage(data?.createUpdateMyInstUserAccount?.message + data.createUpdateMyInstUserAccount.code);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  createInstitutionAdmins$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CreateInstitutionAdmins),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.CREATE_INSTITUTION_ADMINS,
              variables: {
                institutionId: action.institutionId,
                user: action.userDto,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving User"),
              map(({ data }: any) => {
                if (data) {
                  if (data.createInstitutionalAdmin.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.createInstitutionalAdmin.data }));
                    if (action.userDto?.id) {
                      this.notificationService.successMessage("Edited Successfully");
                    } else {
                      this.notificationService.successMessage("Created Successfully");
                    }
                  } else {
                    const error = this.responseCodesService.getCodeDescription(data.createInstitutionalAdmin.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );
  // END OF MARTIN ADD NEW USER
  // createUpdateUserAccount
  createUpdateUserAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.createUpdateUserAccount),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_USER,
              variables: {
                user: action.user,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while saving user"),
              map(({ data }: any) => {
                if (data) {
                  const response = this.responseCodesService?.getCodeDescription(data.createUpdateUserAccount.code);
                  if (data.createUpdateUserAccount.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.createUpdateUserAccount.data }));
                    this.notificationService.successMessage("Operation successful");
                  } else {
                    this.notificationService.determineSnackbar(response[0]);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // Update / Modify User
  modifyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.modifyUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.UPDATE_USER,
              variables: {
                id: action.id,
                user: action.user,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while updating user"),
              map(({ data }: any) => {
                if (data) {
                  if (data.updateUserAccount.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.updateUserAccount.data }));
                    this.notificationService.successMessage("User Updated Successfully");
                  } else {
                    const error = this.responseCodesService.getCodeDescription(data.updateUserAccount.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  activateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.activateUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ACTIVATE_USER,
              variables: {
                id: action.id,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while updating User details"),
              map(({ data }: any) => {
                if (data) {
                  this.notificationService.successMessage("User details Activated Successfully");
                  if (data.activateUser.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.activateUser.data }));
                    this.notificationService.successMessage("User details Activated Successfully");
                  } else {
                    this.notificationService.errorMessage("Failed to Activate User detals");
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  deactivateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deactivateUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.DEACTIVATE_USER,
              variables: {
                id: action.id,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while updating User details"),
              map(({ data }: any) => {
                if (data) {
                  this.notificationService.successMessage("User details Deactivated Successfully");
                  if (data.deactivateUser.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.deactivateUser.data }));
                    this.notificationService.successMessage("User details Deactivated Successfully");
                  } else {
                    this.notificationService.errorMessage("Failed to Deactivate User detals");
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  delateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteUserDetails),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.DELETE_USER,
              variables: {
                id: action.id,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while updating User details"),
              map(({ data }: any) => {
                if (data) {
                  this.notificationService.successMessage("User details Delete Successfully");
                  if (data.deleteUser.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.deleteUser.data }));
                    this.notificationService.successMessage("User details Delete Successfully");
                  } else {
                    this.notificationService.errorMessage("Failed to Delete User detals");
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // Delete User
  removeUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.removeUser),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.DELETE_USER,
              variables: {
                userId: action.userId,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while deleting user"),
              map(({ data }: any) => {
                if (data) {
                  if (data.deleteUserStatus === "SUCCESS") {
                    this.store.dispatch(fromActions.deleteUser({ id: action.userId }));
                    this.notificationService.successMessage("Deleted Successfully");
                  } else {
                    this.notificationService.errorMessage(data.deleteUserStatus);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // Get Institution Users
  listOfUserAccounts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.listOfUserAccounts),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.LIST_ALL_USERS,
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Users"),
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.listOfUserAccounts }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // ggetAllUsersByDepartment
  getAllUsersByDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllUsersByDepartment),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_DEPARTMENT_PERSONNELS,
              fetchPolicy: "network-only",
              variables: {
                departmentId: action.departmentId,
                pageParam: action.pageParam ? action.pageParam : initializedPageableParameter,
              },
            })
            .pipe(
              map(({ data }: any) => {
                if (data) {
                  const result = data?.getAllUsersByDepartment;
                  if (result?.content?.length > 0) {
                    this.paginationService.setPage(result);
                    this.store.dispatch(fromActions.loadUsers({ users: data.getAllUsersByDepartment.content }));
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // assignUsersToDepartment
  assignUsersToDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.assignUsersToDepartment),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ASSIGN_USERS_TO_DEPARTMENT,
              variables: {
                departmentId: action.departmentId,
                userIds: action.userIds,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while adding users to  department"),
              map(({ data }: any) => {
                if (data) {
                  let result: any = data.addUsersToDepartment;
                  if (result?.code === 9000) {
                    this.notificationService.successMessage("You have successfully added users to department");
                    this.store.dispatch(fromActions.upsertUsers({ users: result?.data.users }));
                  } else {
                    const error = this.responseCodesService.getCodeDescription(result?.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // Assign Head Of Department
  assignHeadOfDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.assignHeadOfDepartment),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ASSIGN_HEAD_OF_DEPARTMENT,
              variables: {
                departmentId: action.departmentId,
                userId: action.userId,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while assigning head of department"),
              map(({ data }: any) => {
                if (data) {
                  if (data.assignHeadOfDepartment.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.assignHeadOfDepartment.data }));
                    this.notificationService.successMessage("Head of department Assigned successfully");
                  } else {
                    const error = this.responseCodesService.getCodeDescription(data.assignHeadOfDepartment.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  getMyDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getMyDetails),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_MY_DETAILS,
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching  myDetail"),
              map(({ data }: any) => {
                if (data) {
                  const result: any = Object.values(data)[0];
                  if (result?.code === 9000) {
                    const myDetails: User = result?.data;
                    this.storage.setItem("helpdeskServiceProvider", myDetails?.institution?.helpdeskServiceProvider);
                    this.store.dispatch(fromActions.upsertUser({ user: myDetails }));
                    this.storage.setItem("passwordExpirationDate", myDetails.passwordExpirationDate);
                  } else {
                    const error = this.responseCodesService.getCodeDescription(result?.code);
                    this.notificationService.errorMessage("Failed to get user details " + result?.code);
                    this.store.dispatch(fromActions.loadUsers({ users: [{ id: 0 }] }));
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // get Department Employees
  getEmployeesByDepartmentCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getEmployeesByDepartmentCode),
        switchMap((action) => {
          //
          return this.apollo
            .query({
              query: fromGraphql.LIST_ALL_USERS,
              fetchPolicy: "network-only",
              variables: {
                departmentCode: action.departmentCode,
              },
            })
            .pipe(
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.listOfUserAccounts }));
                  // }
                } else {
                  const error = this.responseCodesService.getCodeDescription(data.userInDepartment);
                  this.notificationService.errorMessage(error[0]?.description);
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // get Department Employees
  userDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.userDetails),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_DETAILS_BY_ID,
              fetchPolicy: "network-only",
              variables: {
                id: action.userId,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching department Users"),
              map(({ data }: any) => {
                if (data) {
                  if (data.userDetails.code === 9000) {
                    this.store.dispatch(fromActions.upsertUser({ user: data.userDetails.data }));
                  } else {
                    const error = this.responseCodesService.getCodeDescription(data.userDetails.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // ROSTER_FIRST_LEVEL_USERS
  rosterlistAllUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.listAllUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.LIST_ALL_USERS,
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Users"),
              map(({ data }: any) => {
                if (data) {
                  this.store.dispatch(fromActions.loadUsers({ users: data.listOfUserAccounts }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // departmentuser
  getMyDepartmentUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyDepartmentUsers),
      switchMap((action) => {
        return this.apollo
          .query({
            query: fromGraphql.GET_DEPARTMENT_USERS,
            variables: {
              serviceUuid: action.serviceUuid,
              branchUuid: action?.branchUuid ? action.branchUuid : null,
            },
            fetchPolicy: "network-only",
          })
          .pipe(
            this.notificationService.catchError("Problem occured while listing department users"),
            map(({ data }: any) => {
              if (data) {
                let result: any = Object.values(data)[0];

                if (result?.code === 9000) {
                  return fromActions.loadUsers({ users: result?.dataList });
                } else {
                }
              }
            })
          );
      })
    )
  );

  // removeUserFromDepartment
  removeUsersFromDepartment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.removeUserFromDepartment),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.REMOVE_USER_FROM_DEPARTMENT,
              variables: {
                departmentId: action.departmentId,
                userIds: action.userIds,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while assigning head of department"),
              map(({ data }: any) => {
                if (data) {
                  let result = data.removeUsersFromDepartment;
                  if (result?.code === 9000) {
                    this.notificationService.successMessage("Users removed from department successfully");
                    this.store.dispatch(fromActions.deleteUsers({ ids: action.userIds }));
                  } else {
                    const error = this.responseCodesService.getCodeDescription(result?.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  userInfoById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.userInfoById),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.USER_INFO_BY_ID,
              variables: {
                id: action.id,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching User dETAILS"),
              map(({ data }: any) => {
                let result = data.userInfoById;
                if (result?.code === 9000) {
                  this.store.dispatch(fromActions.upsertUser({ user: data.userInfoById.data }));
                } else {
                  const error = this.responseCodesService.getCodeDescription(result?.code);

                  this.notificationService.errorMessage(error[0]?.description);
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // assignUsersToService
  assignUsersToService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.assignUsersToService),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.ASSIGN_USER_TO_SERVICE,
              variables: {
                userUuidList: action.userUuidList,
                serviceUuid: action.serviceUuid,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while assigning  Users to Services"),
              map(({ data }: any) => {
                if (data) {
                  const result = data.assignUsersToService;
                  if (result?.code === 9000) {
                    this.notificationService.successMessage("Users Added to Service successfully");
                    this.store.dispatch(fromActions.upsertUsers({ users: result?.dataList }));
                  } else {
                    this.notificationService.handleErrorMessage(result);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  getAllUsersAssignedToService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllUsersAssignedToService),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_USERS_ASSIGNED_TO_SERVICE,
              variables: {
                serviceUuid: action.serviceUuid,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching User dETAILS"),
              map(({ data }: any) => {
                let result = data.getAllUsersAssignedToService;

                if (result?.code === 9000) {
                  this.store.dispatch(fromActions.loadUsers({ users: result?.dataList }));
                } else {
                  const error = this.responseCodesService.getCodeDescription(result?.code);

                  this.notificationService.errorMessage(error[0]?.description);
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // removeUsersFromService
  removeUsersFromService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.removeUsersFromService),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.REMOVE_USER_FROM_SERVICE,
              variables: {
                userUuidList: action.userUuidList,
                serviceUuid: action.serviceUuid,
              },
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while Removing User"),
              map(({ data }: any) => {
                if (data) {
                  let result = data.removeUsersFromService;
                  if (result?.code === 9000) {
                    this.notificationService.successMessage("Users Removed Successfully");
                    this.store.dispatch(fromActions.deleteUser({ id: action.id }));
                  } else {
                    const error = this.responseCodesService.getCodeDescription(result?.code);
                    this.notificationService.errorMessage(error[0]?.description);
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

// unsubscribeFromAllServices
unsubscribeFromAllServices$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.unsubscribeFromAllServices),
    switchMap((action) => {
        return this.apollo.mutate({
            mutation: fromGraphql.REMOVE_USER_FROM_ALL_SERVICES,
            variables: {
                userUuid: action.userUuid
            }
        }).pipe(
            this.notificationService.catchError('Problem occurred while Removing User'),
            map(({data}: any) => {
                if (data) {
                    let result = data.removeUserFromAllServices;
                    if (result?.code === 9000) {
                        this.notificationService.successMessage('Users Unsubscribed from all services Successfully');
                    } else {
                        const error = this.responseCodesService.getCodeDescription(result?.code);
                        this.notificationService.errorMessage(result?.message ? result?.message : error[0]?.description);
                    }
                }
            })
        );
    })
), {dispatch: false});

  getAllUsersFoundInServiceGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllUsersFoundInServiceGroup),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_USER_FOUND_IN_SERVICE_GROUP,
              variables: {
                groupUuid: action.groupUuid,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching User Details"),
              map(({ data }: any) => {
                let result = data.getAllUsersFoundInServiceGroup;

                if (result?.code === 9000) {
                  this.store.dispatch(fromActions.loadUsers({ users: result?.dataList }));
                } else {
                  const error = this.responseCodesService.getCodeDescription(result?.code);

                  this.notificationService.errorMessage(error[0]?.description);
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  // departmentuser
  getNewMyDepartmentUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNewMyDepartmentUsers),
      switchMap((action) => {
        return this.apollo
          .query({
            query: fromGraphql.GET_NEW_DEPARTMENT_USERS,
            variables: {
              departmentUuid: action.departmentUuid,
            },
            fetchPolicy: "network-only",
          })
          .pipe(
            this.notificationService.catchError("Problem occured while listing department users"),
            map(({ data }: any) => {
              if (data) {
                let result: any = Object.values(data)[0];
                return fromActions.loadUsers({ users: result || [] });
              }
            })
          );
      })
    )
  );

  //  list user by Institution
  getTotalInstitutionUsers = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getTotalInstitutionUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_INSTITUTION_USER_COUNTS,
              variables: {
                institutionId: action.institutionId,
                pageable: action?.pageable ? action.pageable : initializedPageableParameter,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Institution Users"),
              map(({ data }: any) => {
                if (data) {
                  const result = data?.getAllUsersByInstitutionId;
                  if (result?.content?.length > 0) {
                    this.paginationService.setPage(result);
                    this.store.dispatch(fromActions.loadUsers({ users: data?.getAllUsersByInstitutionId?.content || [] }));
                  }
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  //  list user by Institution
  getNonEmployeeUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getNonEmployeeUsers),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_NON_EMPLOYEE_USERS_BY_INSTITUTION,
              variables: {
                institutionUuid: action.institutionId ? action.institutionId : null,
                pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                branchUuid: action?.branchUuid ? action?.branchUuid : null,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while non employee Users"),
              map(({ data }: any) => {
                if (data) {
                  const result = data?.getRegisterIndividualUsersByInstitutionBranch;
                  this.paginationService.setPage(result);
                  this.store.dispatch(fromActions.loadUsers({ users: result?.content }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  //  list user by Institution
  clientsUsersSubscribedForServicesInMyInst$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.clientsUsersSubscribedForServicesInMyInst),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_NON_EMPLOYEE_SUBSCRIBERS,
              variables: {
                search: action.search ? action.search : null,
                pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
              },
              fetchPolicy: "network-only",
            })
            .pipe(
              this.notificationService.catchError("Problem occurred while fetching Institution Users"),
              map(({ data }: any) => {
                if (data) {
                  const result = data?.clientsUsersSubscribedForServicesInMyInst;
                  this.paginationService.setPage(result);
                  this.store.dispatch(fromActions.loadUsers({ users: result?.content || [] }));
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private apollo: Apollo, private store: Store<AppState>, private notificationService: NotificationService, private settingService: SettingsService, private authService: AuthService, private storage: StorageService, private responseCodesService: ResponseCodeService, private paginationService: PaginationService, private dialog: MatDialog) {}

  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertUser({ user: data?.data }));
      return this.notificationService.successMessage("Action done Successful");
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodesService.getCodeDescription(data.code);
    const message = responseCode[0].code + " : " + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }
}
