import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {userInfoById} from 'src/app/store/entities/settings/user/user.actions';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {selectModifiedUsers} from 'src/app/store/entities/settings/user/user.selectors';
import {AppState} from 'src/app/store/reducers';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-more-details',
    templateUrl: './more-details.component.html',
    styleUrls: ['./more-details.component.scss']
})
export class MoreDetailsComponent implements OnInit {
    user: User;
    user$: Observable<User>;
    title: string;

    constructor(
        private dialogRef: MatDialogRef<MoreDetailsComponent>,
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.user = data;
        this.store.dispatch(userInfoById({id: this.user.id}));
    }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(selectModifiedUsers)).pipe(map(data => {
            return data.find(res => res.id === this.user.id);
        }));
        this.title = 'More User details';
    }

    closeModal(close: boolean): void {
        if (close) {
            this.dialogRef.close();
        }
    }
}
