import {StorageService} from './storage.service';
import {environment} from './../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {select, Store} from '@ngrx/store';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppState} from '../store/reducers';
import {AuthUser} from '../store/entities/auth-user/auth-user.model';
import {loadAuthUsers} from '../store/entities/auth-user/auth-user.actions';
import {Apollo} from 'apollo-angular';
import {clearUsers, getMyDetails} from '../store/entities/settings/user/user.actions';
import {selectAllUsers} from '../store/entities/settings/user/user.selectors';
import {User} from '../store/entities/settings/user/user.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {LockDialogComponent} from '../shared/components/lock-dialog/lock-dialog.component';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import {ChangePasswordComponent} from '../shared/components/change-password/change-password.component';
import {SettingsService} from './settings.service';
import {NotificationService} from './notification.service';
import { SpinnerVisibilityService } from 'ng-http-loader';

export interface AuthTokenModel {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    userData: any;
    objectPerms: any;
    perms: any;
    subscription = new Subscription();
    checkForPagination: boolean;
    private errorCode: any;

    constructor(private http: HttpClient, 
        private router: Router, 
        private apollo: Apollo, 
        private snackbar: MatSnackBar, 
        private permissionsService: NgxPermissionsService, 
        private store: Store<AppState>, 
        private storageService: StorageService, 
        private dialogRef: MatDialog, 
        private settingService: SettingsService, 
        private notificationService: NotificationService,
        private spinner: SpinnerVisibilityService
    ) {
    }

    async login(formData: any): Promise<AuthTokenModel> {
        const body = `username=${encodeURIComponent(formData.username)}&password=${encodeURIComponent(formData.password)}&grant_type=password`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: 'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
        });

        // this.spinner.hide();
        // this.notificationService.loaderSnackbar(true)

        return await this.http
            .post<AuthTokenModel>(environment.AUTH_URL + `/oauth/token`, body, {headers})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.storageService.setItem('currentClient', user.access_token);
                    this.storageService.setItem('refreshToken', user.refresh_token);
                    this.storageService.setItem('expireTime', user.expires_in);

                    this.storageService.saveTokenDetails(user);
                    if (user && user.access_token) {
                        this.storageService.saveLogInTime(Date.now().toString());
                    }

                }
                return user;
            })).toPromise();
    }

    requestResetLink(data) {
        const body = `email=${encodeURIComponent(data.email)}`;
        return this.http.post(environment.AUTH_URL + '/requestResetPassword', data);
    }

    alreadyLoggedIn() {
        return !!localStorage.getItem('currentClient');
    }

    logout(): any {
        // remove user from local storage to log user out
        const currentClient = this.storageService.getItem('currentClient');
        const refreshToken = this.storageService.getItem('refreshToken');
        const body = new HttpParams().set('token', currentClient);
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json',
        });

        return this.http
            .post<any>(environment.AUTH_URL + `/oauth/token/revoke?` + body + `&refresh-token=` + refreshToken, {headers})
            .pipe(map(data => {
                if (data.code === 9000) {
                    this.storageService.clearStorage();
                    this.router.navigateByUrl('/login');
                    // logout successful if there's a jwt token in the response
                }
            })).subscribe();

    }

    async authRole(token?: string) {
        if (token) {
            this.storageService.setItem('currentClient', token);
        }
        this.perms = token ? token : localStorage.getItem('currentClient');
        if (this.perms) {
            return await this.http.get(environment.AUTH_URL + '/user').pipe(map(async data => {
                this.userData = data;
                this.objectPerms = this.userData.authorities;
                const finalArray = this.objectPerms.map((obj) => {
                    return obj.authority;
                });

                const userInfo: AuthUser = this.userData.principal;
    
                userInfo.permissions = finalArray;                
                this.storageService.setItem('userID', JSON.stringify(userInfo.id));
                this.storageService.setItem('userType', userInfo.userType);
                this.storageService.setItem('perms', finalArray);
                this.store.dispatch(clearUsers());
                this.store.dispatch(getMyDetails());
                const user: User = await this.store.pipe(select(selectAllUsers), map(items => items[0]), first(i => !!i)).toPromise();
                if (user.id == 0) {
                    return this.logout();
                }

                // add custom permission for helpdesk serviceProviders
                localStorage.removeItem('serviceUserType');

                if (user?.userType === 'nonEmployee') { // shall be replaced with userType
                    this.storageService.setItem('serviceUserType', 'NON_EMPLOYEE');
                } else if (user?.institution?.helpdeskServiceProvider) {
                    userInfo.permissions.push('ROLE_HELPDESK_SERVICE_PROVIDER');
                    this.storageService.setItem('serviceUserType', 'SERVICEPROVIDER');
                } else {
                    this.storageService.setItem('serviceUserType', 'CLIENT');
                }


                this.storageService.setItem('institutionId', JSON.stringify(userInfo.institutionId));
                this.storageService.setItem('defaultInstitution', JSON.stringify(user.institution?.defaultInstitution));
                this.storageService.setItem('canReportToBranches', JSON.stringify(user.institution?.canReportToBranches));
                this.storageService.setItem('userUuid', user?.uuid);
                this.storageService.setItem('userId', JSON.stringify(user?.id));
                this.storageService.setItem('branchUuid', user?.branch?.uuid);
                this.storageService.setItem('departmentUid', user?.department?.uuid);
                this.storageService.setItem('institutionUuid', user?.institution?.uuid);
                this.storageService.setItem('institutionName', user?.institution?.name);
                this.store.dispatch(loadAuthUsers({authUsers: [userInfo]}));
                this.permissionsService.loadPermissions(finalArray);
                this.storageService.saveUser(userInfo);

            })).toPromise();
        }

    }

    validateToken(token): Observable<any> {
        return this.http
            .get<any>(environment.AUTH_URL + `/shd/anonymous/verify-token?token=` + token, token);
    }

    createPassword(formData): Observable<any> {
        return this.http
            .post<any>(environment.AUTH_URL + `/shd/anonymous/password_reset?token=` + formData.token, formData);
    }

    forgotPassword(formData): Observable<any> {
        return this.http.post<any>(environment.AUTH_URL + `/shd/anonymous/reset-password?email=` + formData.email, formData);
    }

    handlePasswordExpiration(param: { passwordExpirationDate }) {
        const date1 = new Date(param?.passwordExpirationDate);
        const date2 = new Date();
        const diff = this.settingService?.getDateDiffInDays({date1, date2});
        if (diff <= 0) {
            this.notificationService.warningConfirmation('Credentials Expired, Please Change', 'Change Password').then(dt => {
                if (dt.isConfirmed) {
                    this.openChangePasswordDialog();
                } else {
                    this.handlePasswordExpiration({passwordExpirationDate: param?.passwordExpirationDate});
                }
            });
        }
    }

    openChangePasswordDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        dialogConfig.panelClass = 'incident-details';
        this.dialogRef.open(ChangePasswordComponent, dialogConfig);
    }


    collectFailedRequest(err): void {
        this.errorCode = err.status;
        if (this.errorCode === 400) {
            const message = 'Bad request';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000, verticalPosition: 'top', panelClass: 'red-snackbar'
            });
        }
        if (this.errorCode === 401) {
            const action = 'Dismiss';
            this.snackbar.open('Access denied', action, {
                duration: 5000, verticalPosition: 'top', panelClass: 'red-snackbar'
            });
            const currentClient = this.storageService.getItem('currentClient');
            if (currentClient) {
                this.getRefreshToken();
            }

            this.storageService.clearStorage();
            this.router.navigateByUrl('/login');
        }
        if (this.errorCode === 404) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000, verticalPosition: 'bottom', panelClass: 'red-snackbar'
            });
        }
        if (this.errorCode === 500) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000
            });
        }
        if (this.errorCode === 504) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000, verticalPosition: 'bottom', panelClass: 'red-snackbar'
            });
        }
        if (this.errorCode === 0) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000, verticalPosition: 'bottom', panelClass: 'red-snackbar'
            });
        }
    }


    async lockScreen() {
        if ((this.dialogRef?.openDialogs || [])?.length > 0) {
            return;
        }
        this.storageService.saveScreenLocked(true);
        const dialogRef = this.dialogRef.open(LockDialogComponent, {
            // maxWidth: '100vw',
            // maxHeight: '100vh',
            // height: '100%',
            // width: '100%',
            disableClose: true,
            data: this.storageService.getUser(),
            backdropClass: 'backdropClass',
            autoFocus: false,
            scrollStrategy: new NoopScrollStrategy(),
        });

        await dialogRef.afterClosed().pipe(first()).toPromise();
        this.storageService.saveScreenLocked(false);


    }


    async getRefreshToken(): Promise<AuthTokenModel> {
        const body = `grant_type=refresh_token`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: 'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
        });

        return await this.http
            .post<AuthTokenModel>(environment.AUTH_URL + `/oauth/token`, body, {headers})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.storageService.setItem('currentClient', user.access_token);
                    this.storageService.setItem('refreshToken', user.refresh_token);
                    this.storageService.setItem('expireTime', user.expires_in);

                    this.storageService.saveTokenDetails(user);
                    if (user && user.access_token) {
                        this.storageService.saveLogInTime(Date.now().toString());
                    }

                }
                return user;
            })).toPromise();
    }

}
