import {Pipe, PipeTransform} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {USER_INFOFEW_FIELDS_ID} from 'src/app/store/entities/settings/user/user.graphql';

@Pipe({
    name: 'userId'
})
export class UserInfoPipe implements PipeTransform {

    constructor(private apollo: Apollo) {
    }

    transform(value: any, args?: any): any {
        if (value) {
            return this.apollo.query({
                query: USER_INFOFEW_FIELDS_ID,
                variables: {
                    id: value
                },
                fetchPolicy: 'network-only'
            })
                .pipe(
                    map(({data}: any) => {
                        if (data?.userInfoById?.code === 9000) {
                            let fullName = data?.userInfoById?.data?.fullName;
                            fullName = fullName.replace(null, ' ');
                            return fullName;
                        }
                    }));
        }
    }
}
