import { Component, OnInit,Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { Roster } from 'src/app/store/entities/roster/roster.model';
import { selectModifiedRosters } from 'src/app/store/entities/roster/roster.selectors';
import { userInfoById } from 'src/app/store/entities/settings/user/user.actions';
import { User } from 'src/app/store/entities/settings/user/user.model';
import { selectModifiedUsers } from 'src/app/store/entities/settings/user/user.selectors';
import { AppState } from 'src/app/store/reducers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user$: Observable<User[]>;
  rosters$: Observable<Roster[]>;
  user?: User;
  
  constructor(
    private storageService: StorageService,
    private settingsService: SettingsService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    let userId = +storageService.getItem('userId');
    this.store.dispatch(userInfoById({ id: userId}));
  }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(selectModifiedUsers));
    this.rosters$ = this.store.pipe(select(selectModifiedRosters));
  }


}
