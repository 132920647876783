import {ResponseCodeService} from './../../../../services/response-code.service';
import {NotificationService} from './../../../../services/notification.service';
import {AppState} from './../../../reducers/index';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import * as fromActions from './role.actions';
import * as fromGraphql from './role.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter} from 'src/app/interfaces/global.interface';


@Injectable()
export class RoleEffects {

    // List all Roles
    listAllRoles$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllRoles),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_OF_ALL_ROLES,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Roles'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadRoles({roles: data.listOfRoles}));
                    }
                })
            );
        })
    ), {dispatch: false});


    allRolesPageable$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllRoles),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_OF_ALL_ROLES_PAGEABLE,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Roles'),
                map(({data}: any) => {
                    if (data) {
                        let result = data.allRolesPageable;
                        this.store.dispatch(fromActions.loadRoles({roles: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // allRolesPageable
    listAllRolesPageable$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllRolesPageable),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_OF_ALL_ROLES_PAGEABLE,
                fetchPolicy: 'network-only',
                variables: {
                    pageParam: action?.pageableParam ? action.pageableParam : initializedPageableParameter,
                    institutionUuid: action?.institutionUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Roles'),
                map(({data}: any) => {
                    if (data) {
                        let result = data.allRolesPageable;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadRoles({roles: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // Save / Edit Role
    saveRole$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.saveRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_UPDATE_ROLE,
                variables: {
                    role: action.roleDto,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Role'),
                map(({data}: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.createUpdateRole.code);
                        if (data.createUpdateRole.code === 9000) {
                            this.store.dispatch(fromActions.upsertRole({role: data.createUpdateRole.data}));
                            if (action.roleDto.uuid) {
                                this.notificationService.successMessage('Role updated successfully');
                            } else {
                                this.notificationService.successMessage('Role created successfully');
                            }
                        } else {
                            this.notificationService.errorMessage('Failed to create or update role: ' + data?.createUpdateRole?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // Remove / Delete
    removeRole$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_ROLE,
                variables: {
                    roleUuid: action.roleUuid,
                    roleId: action.roleId
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting role'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteRole.code === 9000) {
                            this.store.dispatch(fromActions.deleteRole({id: action.roleId}));
                            this.notificationService.successMessage('Role deleted successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.deleteRole.message);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // copyRoles
    copyRoles$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.copyRoles),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.COPY_ROLES,
                variables: {
                    institutionUuid: action.institutionUuid,
                    roleUuid: action.roleDto
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while copying role'),
                map(({data}: any) => {
                    if (data) {
                        const result = data.copyRoleToOtherInstitution;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertRoles({roles: result?.dataList}));
                            this.notificationService.successMessage('Roles copied successful');
                        } else {
                            this.notificationService.errorMessage('Failed to copy roles' + result?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // getRoles by Id
    getRoleByID$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getRoleByID),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ROLE_BY_ID,
                variables: {
                    id: action.roleId,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting role datails'),
                map(({data}: any) => {
                    if (data) {
                        if (data.getRoleById.code === 9000) {
                            this.store.dispatch(fromActions.upsertRole({role: data.getRoleById.data}));
                        } else {
                            this.notificationService.errorMessage(data?.getRoleById?.message + data?.getRoleById?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private paginationService: PaginationService
    ) {
    }

}
